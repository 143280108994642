import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, TrailguidePlugin } from "../../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`The Aletsch glacier with over 20 km length is the biggest of the Alps. It has
its origin high up at famous peaks like the Mönch and Jungfrau. Further
down where the ice is already on its way for hundred years, you can ride on
the alpine trails of the Bettmergrat with a stunning view on the lazy white
snake.`}</p>
    <br />
    <p>{`Between Brig and Fiesch you can use the train and lifts with one
ticket that opens up a wide area with many descents. When you enter the woods
below the Bettmergrat, you still have steep 1200vm down to the valley. The
trails are challenging and more on the technical side. Best time of the year
is July to September.`}</p>
    <TrailguidePlugin content="lat=46.3913&lng=8.0419" mdxType="TrailguidePlugin" />
    <p className="text-xs mb-16" align="center">
      <p>{`Open these trails directly in the `}<a parentName="p" {...{
          "href": "https://trailguide.net/?pos=13,46.3792,8.0648"
        }}>{`web-app`}</a></p>
    </p>
    <h4>{`Bettmergrat`}</h4>
    <p>{`From the highest point you can reach with the gondola, an amazing trail
follows the Bettmergrat. Always with a stunning view on the glacier.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/2271"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/aletsch_5.jpg" mdxType="Image" />
    <br />
    <h4>{`Bettmergrat/ Bettmersee`}</h4>
    <p>{`It starts technical over many rocks.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/2271"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/aletsch_9.jpg" mdxType="Image" />
    <br />
    <h4>{`Bettmergrat/ Bettmersee`}</h4>
    <p>{`The rocks are really grippy and the trail is hardly marked,
so you can choose creative lines.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/2271"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/aletsch_10.jpg" mdxType="Image" />
    <br />
    <h4>{`Bettmersee`}</h4>
    <p>{`The Bettmersee trail turns left, away from the ridge.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/2270"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/aletsch_1.jpg" mdxType="Image" />
    <br />
    <h4>{`Z'Matt`}</h4>
    <p>{`Lower down it is less alpine.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/2268"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/aletsch_2.jpg" mdxType="Image" />
    <br />
    <h4>{`Slick Rock`}</h4>
    <p>{`But the trails are steep and technical.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/2269"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/aletsch_7.jpg" mdxType="Image" />
    <br />
    <h4>{`Slick Rock`}</h4>
    <p>{`Good the rocks are grippy.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/2269"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/aletsch_8.jpg" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      